<template>
  <div class="margin-top-xl">
    <!-- title && action button -->
    <div class="profile-title margin-bottom-xs">
      <div
        class="heading-small"
        v-text="$translations.profile['personal-information']"
      />
      <Button
        class="padding-y-zero padding-x-zero button"
        type="link"
        :class="{ disabled: loadingState }"
        :text="$translations.general[enableForm ? 'save' : 'edit']"
        @clicked="hdleToggleForm"
      />
    </div>

    <!-- loader -->
    <FormLoader :rows="6" :loading="loadingState" v-if="loadingState" />

    <!-- form -->
    <div v-else>
      <!-- first_name & last_name -->
      <div class="name-inputs">
        <!-- first_name -->
        <InputTextField
          id="first_name"
          v-model="form.first_name"
          :label="$translations.form['first-name']"
          :disabled="!enableForm"
        />

        <!-- last_name -->
        <InputTextField
          id="last_name"
          v-model="form.last_name"
          :label="$translations.form['last-name']"
          :disabled="!enableForm"
        />
      </div>

      <!-- gender -->
      <DropdownField
        class="margin-top"
        :label="$translations.form.gender"
        :options="options.genders"
        v-model="form.gender"
        :mobile-options="true"
        :disabled="!enableForm"
      />

      <!-- birthday -->
      <div class="margin-top">
        <label for="birthday" v-text="$translations.form.birthday" />
        <DateField
          id="birthday"
          v-model="form.birthday"
          :label="$translations.form['birthday']"
          :placeholder-day="$translations.form['date-placeholder'].day"
          :placeholder-month="$translations.form['date-placeholder'].month"
          :placeholder-year="$translations.form['date-placeholder'].year"
          :mobile-options="true"
          :lang="$store.getters.language.lang"
          :disabled="!enableForm"
        />
      </div>

      <!-- phone -->
      <PhoneField
        class="margin-top"
        v-model="phoneValue"
        :mobile-options="true"
        :label="$translations.form.phone"
        :placeholder-phone="$translations.form['phone-placeholder-alt']"
        :disabled="!enableForm"
        :disabled-country="!enableForm"
      />
    </div>
  </div>
</template>

<script>
import FormLoader from '@/components/loaders/FormLoader'
import { Genders } from '@/constants/genders'
import { Users, Button } from '@seliaco/red-panda'
import {
  InputTextField,
  DropdownField,
  DateField,
  PhoneField
} from '@seliaco/sea-otter'

export default {
  name: 'ProfilePersonalForm',
  components: {
    FormLoader,
    PhoneField,
    Button,
    InputTextField,
    DropdownField,
    DateField
  },
  props: {
    patient: {
      id: String,
      first_name: String,
      last_name: String,
      birthday: String,
      gender: String,
      address: String,
      dial_code: String,
      phone: String
    },
    isLoading: Boolean
  },
  data () {
    return {
      loading: true,
      form: {
        first_name: '',
        last_name: '',
        birthday: null,
        gender: '',
        address: '',
        dial_code: '',
        phone: ''
      },
      phoneValue: {},
      options: {
        genders: []
      },
      enableForm: false
    }
  },
  methods: {
    submit () {
      this.loading = true
      Users.update(
        {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          birthday: this.form.birthday,
          gender: this.form.gender,
          dial_code: `+${this.form.dial_code}`,
          phone: this.phoneValue.phone,
          dial_country_code: this.phoneValue.country
        },
        { id: this.$store.getters['auth/user'].id }
      )
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.success.default
          })

          this.loading = false
          this.enableForm = false
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: 'No fue posible actualizar el contenido'
          })
          this.loading = false
        })
    },
    setForm () {
      this.loading = true
      this.options.genders = Genders(this.$translations)
      this.form = {
        first_name: this.patient.first_name,
        last_name: this.patient.last_name,
        birthday: this.patient.birthday
          ? this.$moment(this.patient.birthday)
            .tz(this.$store.getters.userTimezone)
            .toDate() : null,
        gender: this.patient.gender || '',
        address: this.patient.address || null,
        dial_code: this.patient.dial_code?.replace('+', '') || null,
        phone: this.patient.phone || null
      }

      this.phoneValue = {
        phone: this.form.phone,
        dialCode: this.form.dial_code,
        country: this.patient.dial_country_code
      }

      this.loading = false
    },
    hdleToggleForm () {
      if (this.enableForm) {
        this.submit()
      } else {
        this.enableForm = true
      }
    }
  },
  computed: {
    loadingState () {
      return this.loading || this.isLoading
    },
    isValid () {
      return Boolean(
        this.form.first_name &&
          this.form.last_name &&
          this.form.gender &&
          this.form.birthday &&
          this.form.dial_code &&
          this.form.phone
      )
    }
  },
  watch: {
    patient: {
      handler (patient) {
        if (patient && patient.id) {
          this.setForm()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.profile-title
  display: grid
  grid-template-columns: 1fr min-content
  .button
    height: auto

.name-inputs
  display: flex
  gap: 8px

.disabled ::v-deep .field input
  background-color: unset !important
</style>
