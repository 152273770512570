<template>
  <div>
    <!-- title && action button -->
    <div class="profile-title margin-bottom-xs">
      <div class="heading-small">
        {{ $translations.profile['security-information'] }}
      </div>
      <Button
        class="padding-y-zero padding-x-zero button"
        type="link"
        :text="$translations.general.edit"
        @clicked="$router.push({ name: 'ModifyPassword' })"
      />
    </div>

    <!-- loader -->
    <FormLoader :rows="1" :cols="1" :loading="loadingState" />

    <!-- form -->
    <div v-if="!loadingState">
      <InputEmailField
        v-model="email"
        :label="$translations.form.email"
        :disabled="true"
      />
      <!-- password -->
      <div class="p-field margin-top">
        <PasswordField
          id="password"
          :label="$translations.form['password-placeholder']"
          v-model="form.password"
          :disabled="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormLoader from '@/components/loaders/FormLoader'
import { Button } from '@seliaco/red-panda'
import { PasswordField, InputEmailField } from '@seliaco/sea-otter'

export default {
  name: 'ProfileSecurityForm',
  components: {
    FormLoader,
    Button,
    PasswordField,
    InputEmailField
  },
  props: {
    isLoading: Boolean,
    email: String
  },
  data () {
    return {
      loading: false,
      form: {
        password: '            '
      },
      enableForm: false
    }
  },
  methods: {
    submit () {
      this.loading = true

      this.$auth
        .onUpdatePassword(this.form.password)
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.success.default
          })

          this.loading = false
          this.enableForm = false
        })
        .catch((error) => {
          console.error('Update password error:', error)

          let errorMessage = this.$translations.error.default

          if (error.code === 'auth/requires-recent-login') {
            errorMessage = this.$translations.error[error.code]

            this.$auth.signOut()
            this.$router.push({
              name: 'SignIn'
            })
          }

          this.$toast({
            severity: 'error',
            text: errorMessage
          })
          this.loading = false
        })
    },
    onFormChanged (value, field) {
      this.form[field] = value
    },
    hdleToggleForm () {
      if (this.enableForm) {
        this.submit()
      } else {
        this.enableForm = true
      }
    }
  },
  computed: {
    loadingState () {
      return this.loading || this.isLoading
    },
    isValid () {
      return Boolean(this.form.password)
    }
  }
}
</script>

<style lang="sass" scoped>
.profile-title
  display: grid
  grid-template-columns: 1fr min-content
  .button
    height: auto
</style>
