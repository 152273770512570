<template>
  <PageContainer :title="$translations.profile.title" :back="actions.back">
    <template v-slot:content>
      <div>
        <div class="page-segment">
          <div class="page-segment-content">
            <!-- detail -->
            <ProfileHeader
              :loading="loading"
              :profile_picture="patient?.profile_picture"
            />

            <!-- personal information -->
            <ProfilePersonalForm :loading="loading" :patient="patient" />
          </div>
        </div>

        <template v-if="loading">
          <div class="page-segment" v-for="(v, i) of 3" :key="i">
            <div class="page-segment-content">
              <Skeleton
                class="margin-bottom-xs"
                width="30%"
                height="25px"
                borderRadius="8px"
              />

              <Skeleton width="100%" height="107px" borderRadius="8px" />
            </div>
          </div>
        </template>
        <template v-else>
          <!-- multi country settings -->
          <div class="page-segment">
            <div class="page-segment-content">
              <LocalizationSettingsSection
                :router="$router"
                :lang="$store.getters.language.lang"
              />
            </div>
          </div>

          <!-- benefit -->
          <div class="page-segment" v-if="company.enabled">
            <div class="page-segment-content">
              <BenefitSection />
            </div>
          </div>

          <!-- security information -->
          <div class="page-segment">
            <div class="page-segment-content">
              <ProfileSecurityForm
                v-if="patient && patient.email"
                :loading="loading"
                :email="patient.email"
              />
            </div>
          </div>

          <!-- emergency contact -->
          <div class="page-segment" v-if="patient">
            <div class="page-segment-content">
              <EmergencyContactForm
                :loading="loading"
                :emergency-contact="patient.emergency_contact"
              />
            </div>
          </div>

          <!-- delete account -->
          <div class="page-segment">
            <div
              class="page-segment-content delete-account"
              @click="deleteAccountView"
            >
              <span class="icon-warning-triangle-on icon"></span>
              <span class="text" v-text="$translations.account.delete.title" />
            </div>
          </div>
        </template>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  PageContainer,
  LocalizationSettingsSection,
  Skeleton
} from '@seliaco/red-panda'
import ProfileHeader from '@/views/profile/components/Header'
import ProfilePersonalForm from '@/views/profile/components/ProfilePersonalForm'
import ProfileSecurityForm from '@/views/profile/components/ProfileSecurityForm'
import EmergencyContactForm from '@/views/profile/components/EmergencyContactForm'
import BenefitSection from '@/views/profile/components/BenefitSection'
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  components: {
    EmergencyContactForm,
    ProfileSecurityForm,
    ProfilePersonalForm,
    PageContainer,
    ProfileHeader,
    Skeleton,
    LocalizationSettingsSection,
    BenefitSection
  },
  data () {
    return {
      emergencyContact: null,
      actions: {
        back: {
          callback: () => {
            this.$router.push({ name: 'Home' })
          }
        }
      }
    }
  },
  created () {
    this.getProfile()
  },
  methods: {
    getProfile () {
      this.$store.dispatch('profile/get', { user: this.user })
    },
    deleteAccountView () {
      this.$router.push({
        name: 'DeleteAccount'
      })
    }
  },
  computed: {
    ...mapGetters({
      patient: 'profile/getProfile',
      loading: 'profile/getLoading',
      user: 'auth/user',
      company: 'auth/company'
    })
  }
}
</script>

<style lang="sass" scoped>
.delete-account
  display: grid
  grid-template-columns: min-content 1fr
  justify-content: start
  gap: 4px
  color: var(--red)
  cursor: pointer
  .icon
    align-self: center
    font-size: 16px
  .text
    align-self: center
    font-size: 14px
</style>
