<template>
  <div class="border border-purple-20 rounded-2xl p-2 bg-gray-5">
    <!-- title && action button -->
    <div class="profile-title margin-bottom-xs">
      <div class="heading-small">
        {{ $translations.profile['emergency-contact'] }}
      </div>
      <Button
        class="padding-y-zero padding-x-zero button"
        :class="{ disabled: loadingState }"
        :type="'link'"
        :text="
          enableForm ? $translations.general.save : $translations.general.edit
        "
        @clicked="hdleToggleForm"
      />
    </div>

    <!-- loader -->
    <FormLoader :rows="2" :cols="1" :loading="loadingState" />

    <!-- form -->
    <div v-show="!loadingState">
      <!-- display_name -->
      <div class="p-field">
        <InputTextField
          id="first_name_contact"
          v-model="form.display_name"
          :label="$translations.form.name"
          :disabled="!enableForm"
        />
      </div>

      <!-- phone -->
      <div class="p-field margin-top">
        <PhoneField
          v-model="phoneValue"
          :label="$translations.form['phone']"
          :disabled="!enableForm"
          :disabled-country="!enableForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormLoader from '@/components/loaders/FormLoader'
import { EmergencyContacts, Button } from '@seliaco/red-panda'
import { InputTextField, PhoneField } from '@seliaco/sea-otter'
import { mapGetters } from 'vuex'

export default {
  name: 'EmergencyContactForm',
  components: { PhoneField, FormLoader, Button, InputTextField },
  props: {
    isLoading: Boolean,
    emergencyContact: {
      id: String,
      display_name: String,
      phone: String,
      country: String
    }
  },
  data () {
    return {
      loading: false,
      dial_code: '57',
      phoneValue: {},
      form: {
        display_name: '',
        country: '',
        phone: ''
      },
      enableForm: false
    }
  },
  methods: {
    submit () {
      this.loading = true

      this.form = {
        ...this.form,
        country: this.phoneValue.country,
        phone: this.phoneValue.phone
      }

      EmergencyContacts.update(this.form, {
        id: this.emergencyContact.id
      })
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.success.default
          })

          this.loading = false
          this.enableForm = false
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: 'No fue posible actualizar el contenido'
          })

          this.loading = false
        })
    },
    hdleToggleForm () {
      if (this.enableForm) {
        this.submit()
      } else {
        this.enableForm = true
      }
    },
    setForm () {
      this.loading = true

      this.form = {
        display_name: this.emergencyContact.display_name,
        phone: this.emergencyContact.phone,
        country: this.emergencyContact.country
      }

      if (this.emergencyContact.country) {
        const countries = this.settings.countries
        const country = countries.find(
          (country) => country.value === this.emergencyContact.country
        )
        this.dial_code = country.dial_code.replace('+', '')
      }

      if (this.emergencyContact.phone) {
        this.phoneValue = {
          dialCode: this.dial_code,
          phone: this.emergencyContact.phone,
          country: this.emergencyContact.country
        }
      }
      this.loading = false
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings'
    }),
    loadingState () {
      return this.loading || this.isLoading
    },
    isValid () {
      return Boolean(
        this.form.display_name && this.form.phone && this.form.country
      )
    }
  },
  watch: {
    emergencyContact: {
      handler (emergencyContact) {
        if (emergencyContact && emergencyContact.id) {
          this.setForm()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.profile-title
  display: grid
  grid-template-columns: 1fr min-content
  .button
    height: auto
</style>
