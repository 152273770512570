<template>
  <div class="benefit-section">
    <template v-if="!loading">
      <!-- title && action button -->
      <div class="heading-small" v-text="title" />
      <div class="heading-tag" v-html="subtitle" />
      <!-- title && action button -->
      <div class="benefit-section-group">
        <div
          class="benefit-section-item"
          v-for="(benefit, index) of benefits"
          :key="index"
        >
          <div v-text="benefit.description" />
          <div />
        </div>
      </div>
    </template>

    <template v-else>
      <Skeleton
        width="40%"
        height="22px"
        borderRadius="8px"
        class="margin-bottom-sm"
      />
      <Skeleton width="100%" height="67px" borderRadius="8px" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Promos, Skeleton } from '@seliaco/red-panda'
export default {
  name: 'BenefitSection',
  components: {
    Skeleton
  },
  data () {
    return {
      title: '',
      subtitle: '',
      benefits: [],
      loading: true
    }
  },
  created () {
    this.b2bInit()
  },
  methods: {
    async b2bInit () {
      const b2b = await Promos.Benefits.getBenefits().catch(() => false)
      const lang = this.language.lang
      const benefitTranslations = this.$translations['sign-up-dialog']
      const benefitProfileTranslations = this.$translations.profile.benefit

      this.title = benefitProfileTranslations.title
      this.subtitle = benefitProfileTranslations.subtitle.replace(
        '{COMPANY}',
        b2b.company.name
      )

      for (const element of b2b.benefits) {
        let description = benefitTranslations.benefit.percent

        let service = element.products[0][`name_${lang}`]
        if (element.products.length > 1) {
          service = element.products.reduce(
            (acc, cur) =>
              acc ? `${acc}, ${cur[`name_${lang}`]}` : cur[`name_${lang}`],
            ''
          )
        }

        if (element.is_first_appointment) {
          description = benefitTranslations.benefit['percent-first-session']
        }

        if (element.uses) {
          description = benefitTranslations.benefit['percent-uses']
          description = description.replace('{uses}', element.uses)
        }

        description = description.replace('{percent}', element.percentage_value)
        description = description.replace('{service}', service)

        this.benefits.push({ description })
        this.loading = false
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      language: 'language'
    })
  }
}
</script>

<style lang="sass" scoped>
.benefit-section
  .heading-tag
    font-weight: 500
    margin-top: 16px
    margin-bottom: 8px
    color: var(--gray-60)

  &-group
    display: flex
    color: var(--gray-60)
    flex-direction: column
    gap: 16px
  &-item
    border-bottom: 1px solid var(--gray-20)
    padding-bottom: 8px
    &:last-child
      border-bottom: none
</style>
