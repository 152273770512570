<template>
  <div v-if="loading">
    <div class="grid gap-1" :class="gridCols">
      <div class="mb-2" v-for="i in rows" :key="i">
        <!-- label -->
        <div class="mb-0.75">
          <Skeleton width="60px" height="12px" borderRadius="8px" />
        </div>
        <!-- input -->
        <div>
          <Skeleton width="100%" height="48px" borderRadius="8px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton } from '@seliaco/red-panda'

export default {
  name: 'FormLoader',
  components: { Skeleton },
  props: {
    rows: {
      type: Number,
      default: 6
    },
    cols: {
      type: Number,
      default: 2
    },
    loading: Boolean
  },
  computed: {
    gridCols () {
      return `grid-cols-${this.cols}`
    }
  }
}
</script>
